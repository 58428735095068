import axios from "axios";
import React, { useEffect, useState } from "react";
import "./alKabeerContest.scss";

const Winners = () => {
  const [winnersSelect, setWinnersSelect] = useState([]);
  const [contextNo, setContextNo] = useState("");

  const getWinners = async () => {
    try {
      const response = await axios.get(
        `/al-kabeer-contest-get-winners/${contextNo}`
      );
      setWinnersSelect(response.data.winners);
    } catch (error) {
      console.error("Error fetching winners:", error);
    }
  };

  const sendMail = async (name,gift,place,) => {
    console.log("name", name);
    if (!name,!gift,!place) {
      return;
    }
    const data = {
      name,
      gift,
      place,
    };
    try {
      await axios.post(`/al-kabeer-contest-send-mail`, data);
    } catch (error) {
        console.log(error)
    }
  };

  return (
    <div className="con_wrp">
      <div className="getaForm">
        <input
          type="text"
          placeholder="Context Number"
          value={contextNo}
          onChange={(e) => setContextNo(e.target.value)}
        />
        <button className="btn" onClick={getWinners}>
          Get Data
        </button>
      </div>
      <div className="data_list">
        <ul>
          {winnersSelect.map((item, i) => (
            <li key={item._id}>
              <div className="win_card">
                <h3>{item.place}</h3>
                <p>{item.name}</p>
                <p>{item.gift}</p>
                <button
                  onClick={() =>
                    sendMail(item.name, item.gift, item.place)
                  }
                >
                  Send Mail
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Winners;
