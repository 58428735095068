import React from 'react'
import "./hero.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Hero = () => {
    const options = {
      loop: true,
      responsiveClass: true,
      center: true,
      items: 1,
      margin: 0,
      autoplay: true,
      dots: true,
      autoplayTimeout: 8500,
      smartSpeed: 450,
      nav: false,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 1,
        },
      },
    };

  return (
    <div className="max-width">
      <div className="slideShow">
        <OwlCarousel className="owl-theme" {...options}>
          <div class="item">
            <img src={require("../../img/01.avif")} />
          </div>
          <div class="item">
            <img src={require("../../img/02.avif")} />
          </div>
          <div class="item">
            <img src={require("../../img/03.avif")} />
          </div>
          <div class="item">
            <img src={require("../../img/04.avif")} />
          </div>
          <div class="item">
            <img src={require("../../img/05.avif")} />
          </div>
          <div class="item">
            <img src={require("../../img/06.avif")} />
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
}

export default Hero
