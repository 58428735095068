import React, { useEffect } from "react";
import WinnerWrp from "../../components/winnerWrp/WinnerWrp";
import { useWinnerContext } from "../../util/WinnerContext";
import "./winner.scss";

const Winner = () => {
  const {
      setContestants,
      setWinners,
      setCountdown,
      setIsPicking,
      winnerCount,
      countdown,
      winners,
    } = useWinnerContext();

  // Debugging: Log values of winners and countdown
 useEffect(() => {
   console.log("Winners in Winner Page:", winners);
   console.log("Countdown in Winner Page:", countdown);
 }, [winners, countdown]);

  return (
    <div className="__mw">
      <div className="win_wrp">
        {winners && winners.length > 0 ? (
          <WinnerWrp
            countdown={countdown}
            winners={winners}
            winnerCount={winnerCount}
          />
        ) : (
          <p>Loading winners...</p>
        )}
      </div>
    </div>
  );
};

export default Winner;
