import React, { createContext, useState, useContext } from "react";

// Creating the Winner Context
const WinnerContext = createContext();

// Custom hook to use the context
export const useWinnerContext = () => useContext(WinnerContext);

export const WinnerProvider = ({ children }) => {
  const [contestants, setContestants] = useState([]);
  const [winners, setWinners] = useState([]); // Initialize as an empty array
  const [countdown, setCountdown] = useState(null); // Initialize as null or 0
  const [winnerCount, setWinnerCount] = useState(1);
  const [isPicking, setIsPicking] = useState(false);

  return (
    <WinnerContext.Provider
      value={{
        contestants,
        setContestants,
        winners,
        setWinners,
        countdown,
        setCountdown,
        winnerCount,
        setWinnerCount,
        isPicking,
        setIsPicking,
      }}
    >
      {children}
    </WinnerContext.Provider>
  );
};
