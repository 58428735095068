import React, { useRef, useState } from "react";
import "./alKabeerContest.scss";
import xlBk from "../../img/xlbk.jpg";
import axios from "axios";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import { Link } from "react-router-dom";

const AlKabeerContest = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error,setError] =useState("")
  const [photos,setPhotos] = useState("")
  const [fileInput, setFileInput] = useState("");
  const [answer,setAnswer]= useState("");
  const [link, setLink] = useState("");

  const fileInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !phone || !link || !answer) {
      setError("All fields are required.");
      return;
    }

    const data = { name, email, phone, photos, answer, link };

    try {
        setLoading(true);
      await axios.post("/al-kabeer-contest", data);
      setMessage("Successfully submitted!");
      setError(""); // Clear any previous error
    } catch (error) {
      setError("Something went wrong. Please try again.");
      setMessage(""); // Clear success message
    }finally{
        setLoading(false)
    }
    setEmail("")
    setName("")
    setPhone("")
    setPhotos("")
    setLink("")
    setAnswer("")
  };

    async function uploadPhoto(e) {
      const files = e.target.files;
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

      const validFiles = [];
      const invalidFiles = [];

      for (let i = 0; i < files.length; i++) {
        if (files[i].size <= maxFileSize) {
          validFiles.push(files[i]);
        } else {
          invalidFiles.push(files[i]);
        }
      }

      // Alert for any files that were too large
      if (invalidFiles.length > 0) {
        setError(
          `${invalidFiles
            .map((file) => file.name)
            .join(", ")} exceed the 5MB file size limit.`
        );
        fileInput.value = null;
      }

      if (validFiles.length > 0) {
        const data = new FormData();
        validFiles.forEach((file) => data.append("idImage", file));

        try {
          const response = await axios.post(
            "https://al-kabeer-contest.notionsgroup.com/id-upload",
            //"http://localhost:9876/id-upload",
            data,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          const { data: filenames } = response;
          setPhotos((prev) => [...prev, ...filenames]); // Update state with new file names
          setError("");
        } catch (error) {
          console.error("Error uploading files:", error);
          setError("File upload failed.");
        }
      }
    }

  return (
    <div
      className="context_wrp"
      style={{
        backgroundImage: `url(${xlBk})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
    >
      <div className="xlForm">
        <div className="form">
          {message ? (
            <div className="succ_wrp">
              {/* <span style={{ color: "green", fontSize: "14px" }}>
                {message}
              </span> */}
              <p style={{ textAlign: "right" }}>
                !نقدّر مشاركتك معنا 🎉 <br />
                !خلك متابع - سيتم الإعلان عن الفائزين قريبًا
              </p>
              <p>
                We appreciate your participation.
                <br /> Stay tuned—winners will be announced soon! 🎉
              </p>
              <Link to={"/"}>
                <button>Back To Home</button>
              </Link>
            </div>
          ) : (
            <>
              <form onSubmit={handleSubmit}>
                <ul>
                  <li>
                    <span>اسم المتسابق</span>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Contestant's name"
                    />
                  </li>
                  <li>
                    <span>رقم الهاتف المحمول</span>
                    <input
                      type="text"
                      placeholder="Mobile number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </li>
                  <li>
                    <span>البريد الإلكتروني</span>
                    <input
                      type="text"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </li>
                  <li>
                    <span> إجابة السؤال</span>
                    <input
                      type="text"
                      placeholder="The Question Answer"
                      value={answer}
                      onChange={(e) => setAnswer(e.target.value)}
                    />
                  </li>
                  <li>
                    <span> رابط ملف التواصل الاجتماعي</span>
                    <input
                      type="text"
                      placeholder="Social Media Profile Link"
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                    />
                  </li>
                  {/* <li>
                   
                    <div
                      style={{
                        width: "100%",
                        fontSize: "10px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <span style={{ color: "red", fontSize: "10px" }}>
                        (Max file size: 3MB)
                        <em
                          style={{
                            fontStyle: "normal",
                            color: "white",
                            marginLeft: "3px",
                            padding: "1px 3px",
                            backgroundColor: "black",
                            borderRadius: "2px",
                            fontWeight: "600",
                          }}
                        >
                          Optional
                        </em>
                      </span>
                    </div>
                    <div className="inup_wrp">
                      <input
                        ref={fileInputRef}
                        onChange={uploadPhoto}
                        type="file"
                        id="file-input"
                        multiple
                        style={{ display: "none" }}
                      />
                      <div
                        className="up_btn"
                        style={{ backgroundColor: "white", marginTop: "0" }}
                        onClick={() =>
                          document.getElementById("file-input").click()
                        }
                      >
                        <div className="img_wrp">
                          <small style={{ display: "flex", gap: "20px" }}>
                            <AiOutlineCloudUpload size={18} /> Identity soft
                            copy
                          </small>
                          <small>نسخة إلكترونية من الهوية</small>
                        </div>
                      </div>
                      {photos && (
                        <div>
                          <span
                            style={{
                              fontSize: "12px",
                              padding: "3px",
                              display: "flex",
                              alignItems: "center",
                              gap: "15px",
                            }}
                          >
                            {" "}
                            {photos} <TiDelete color="red" />
                          </span>
                        </div>
                      )}
                    </div>
                  </li> */}
                  <li>
                    {loading ? (
                      <button type="submit">Please Wait...</button>
                    ) : (
                      <button type="submit">Submit</button>
                    )}
                  </li>
                </ul>

                <span style={{ color: "red", fontSize: "14px" }}>{error}</span>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlKabeerContest;
