import React from "react";
import "./social.scss";
import {
  InstagramEmbed,
  FacebookEmbed,
  YouTubeEmbed,
} from "react-social-media-embed";

const Social = () => {
  return (
    <div className="social">
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <InstagramEmbed
            url="https://www.instagram.com/p/CyNj4G8h3Sa/"
            width={328}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          borderRadius: "20px",
        }}
      >
        <FacebookEmbed
          url="https://www.facebook.com/chocodateglobal/posts/pfbid0DL26Em3UujoshFChoz94LJME44BmuRDrZDjsYhkMeTFsbnvFuRjpvRMZq9pHz1wkl"
          width={328}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <YouTubeEmbed
          url="https://www.youtube.com/watch?v=HpVOs5imUN0"
          width={328}
          height={220}
        />
      </div>
    </div>
  );
};

export default Social;
