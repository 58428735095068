// components/winnerWrp/WinnerWrp.js
import React from "react";

const WinnerWrp = ({ countdown, winners, winnerCount }) => {
  return (
    <div className="winner_wrp">
      {countdown !== null && countdown > 0 && (
        <div className="countdown">
          <h2>Counting down: {countdown}</h2>
        </div>
      )}

      {Array.isArray(winners) && winners.length > 0 && (
        <div className="the-winners">
          <h1 className="winner-title">
            {winnerCount > 1 ? "And the winners are:" : "And the winner is:"}
          </h1>
          <ul>
            {winners.map((winner, index) => (
              <li key={index} className="winner-name">
                {winner}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default WinnerWrp;
