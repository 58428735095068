import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from 'axios';
import "./alKabeerContest.scss";

const Participants = () => {
const [users, setUsers] = useState([]);
const [loading, setLoading] = useState(false)


    useEffect(()=>{
        
setLoading(true)
        const userData= async()=>{
            try {
                const response = await axios.get(
                  `/al-kabeer-contest-all-users`
                );
                setUsers(response.data);
            } catch (error) {
                console.log(error)
            }finally{
                setLoading(false)
            }
        }
        userData()

    },[])

    console.log(users);


    const columns = [
      { field: "name", headerName: "Name", width: 200 },
      { field: "phone", headerName: "Phone", width: 150 },
      { field: "email", headerName: "Email", width: 200 },
      {
        field: "answer",
        headerName: "Answer",
        width: 250,
      },
      { field: "link", headerName: "Link", width: 300 },
      { field: "createdAt", headerName: "Date", width: 200 },
    ];

    



  return (
    <div className="pt_grid">
      {loading ? (
        <div className="loading">
          <p>Loading....</p>
        </div>
      ) : (
        <div className='data_gird'>
          <DataGrid
            key={users._id}
            //onCellClick={handleCellClick}
            rows={users}
            columns={columns}
            pageSize={5}
            disableRowSelectionOnClick
            initialState={{
              pagination: {
                paginationModel: {
                  // pageSize: 5,
                },
              },
            }}
            getRowId={(row) => row._id}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Participants
