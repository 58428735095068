import { useState, useEffect } from "react";
import "./pickWinner.scss";
import axios from "axios";
import xlBk from "../../img/xlbk.jpg";

const WinnerPicker = () => {
  const [contestants, setContestants] = useState("");
  const [winnerCount, setWinnerCount] = useState(1);
  const [winners, setWinners] = useState([]);
  const [isPicking, setIsPicking] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [displayNames, setDisplayNames] = useState([]);
  const [winnerGifts, setWinnerGifts] = useState([]);

  const gifts = [
    // {
    //   name: "Playstation",
    //   img: require("../../img/gift/playst.png"),
    //   win: "1",
    // },
    // {
    //   name: "Smart Phone",
    //   img: require("../../img/gift/phone.png"),
    //   win: "2",
    // },
    {
      name: "Smart Watch",
      img: require("../../img/gift/wt.png"),
      win: "1",
    },
    {
      name: "Smart Watch",
      img: require("../../img/gift/wt.png"),
      win: "2",
    },
    {
      name: "XL Products",
      img: require("../../img/gift/product.png"),
      win: "3",
    },
    {
      name: "XL Products",
      img: require("../../img/gift/product.png"),
      win: "4",
    },
    {
      name: "XL Products",
      img: require("../../img/gift/product.png"),
      win: "5",
    },
  ];

  const pickWinners = () => {
    let contestantsArray = contestants
      .trim()
      .split("\n")
      .filter((c) => c);

    if (contestantsArray.length < winnerCount) {
      alert("There are more winners than contestants. Everyone is a winner!");
      return;
    }

    setIsPicking(true);
    setWinners([]);
    setWinnerGifts([]);
    setCountdown(10); // Start countdown from 10 seconds

    const nameInterval = setInterval(() => {
      setDisplayNames(
        Array.from(
          { length: winnerCount },
          () =>
            contestantsArray[
              Math.floor(Math.random() * contestantsArray.length)
            ]
        )
      );
    }, 200);

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownInterval);
          clearInterval(nameInterval);
          startPickingWinners(contestantsArray);
        }
        return prevCountdown - 1;
      });
    }, 800);
  };

 const startPickingWinners = (contestantsArray) => {
   let selectedWinners = [];
   let selectedGifts = [];
   let availableContestants = [...contestantsArray];
   let availableGifts = [...gifts]; // Ensure gifts are assigned in the order they appear

   // Ensure the number of winners doesn't exceed available gifts
   if (winnerCount > availableGifts.length) {
     alert("Not enough gifts for the selected number of winners!");
     return;
   }

   // Select winners sequentially and assign gifts based on the gifts array order
   for (let i = 0; i < winnerCount; i++) {
     let randomIndex = Math.floor(Math.random() * availableContestants.length);
     selectedWinners.push(availableContestants[randomIndex]);
     availableContestants.splice(randomIndex, 1);

     // Sequentially assign gifts from the available gifts array
     selectedGifts.push(availableGifts[i]);
   }

   // Map winners and assign their gifts and places
   const winnersWithDetails = selectedWinners.map((winner, index) => {
     const gift = selectedGifts[index];
     return {
       name: winner, // Winner's name
       gift: gift.name, // Name of the gift from the gift array
       place: gift.win, // Place name from the gift array (e.g., "Winner - 1")
       img: gift.img, // Gift image URL
     };
   });

   // Set winners with the gift and place details
   setWinners(winnersWithDetails);
   setWinnerGifts(selectedGifts); // Ensure this is set as well
   setIsPicking(false);
 };

 const context = () => {
   const number = Date.now();
   const bID = "CON-" + number;
   return bID;
 };
 const contextNo = context();

  useEffect(() => {
   if (!winners || winners.length === 0) {
     return;
   }
    const sendWinner = async () => {
      const data = {
        winners,
        contextNo,
      };
      try {
        await axios.post("/al-kabeer-contest-winner", data);
      } catch (error) {
        console.log(error);
      }
    };
    sendWinner();
  }, [winners]);

  return (
    <div
      className="context_wrp"
      style={{
        backgroundImage: `url(${xlBk})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      }}
    >
      <div className="winner-picker">
        <div>
          {countdown !== null && countdown > 0 && (
            <div className="countdown">
              <h1>{countdown}</h1>
              <h2>Picking a Winner...</h2>
              <ul className="random-names">
                {displayNames.map((name, index) => (
                  <li key={index} className="changing-name">
                    {name}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {winners.length > 0 && (
            <div className="the-winners">
              <div className="img_wrp">
                <img src={require("../../img/party.gif")} alt="celebration" />
              </div>
              <div className="winner-title">
                <h2>{winnerCount > 1 ? "والفائزون هم" : "والفائز هو"}</h2>
                <h1>
                  {winnerCount > 1
                    ? "And the winners are"
                    : "And the winner is"}
                </h1>
              </div>
              <ul>
                {winners.map((winner, index) => (
                  <li key={index} className="winner-name">
                    <div className="w_hed">
                      <h3>{winner.place}</h3>{" "}
                      <img
                        src={require("../../img/win.png")}
                        alt="celebration"
                      />
                    </div>
                    {/* This should be a string now */}
                    <h2>{winner.name}</h2> {/* Winner's name */}
                    <img
                      src={winner.img}
                      alt={winner.gift}
                      className="gift-img"
                    />
                    <span>{winner.gift}</span> {/* Winner's gift name */}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {!countdown && (
          <div className="contestant-selection">
            {winners.length === 0 && (
              <>
                <h2>من هو الفائز المحظوظ؟</h2>
                <h2>Who’s the Lucky Winner?</h2>
                <textarea
                  placeholder="List of Contestants"
                  className="contestants"
                  value={contestants}
                  onChange={(e) => setContestants(e.target.value)}
                ></textarea>

                <div className="nub_w">
                  <span>Choose the number of winners</span>
                  <input
                    className="winner-count"
                    type="number"
                    value={winnerCount}
                    min="1"
                    onChange={(e) => setWinnerCount(Number(e.target.value))}
                  />
                </div>
                <button className="btn" onClick={pickWinners}>
                  Pick Winners!
                </button>
              </>
            )}
            <button onClick={() => setWinners([])}>
              <small style={{ fontSize: "12px" }}> Pick Again</small>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default WinnerPicker;
